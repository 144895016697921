import dayjs from "dayjs";
import { TMeta, TRootComponent } from "pages/_models/PageMdl";
import { TUnitListingMdl, TUnitMdl } from "units/_models/UnitMdl";
import { TUserSummaryMdl } from "users/_models/UserMdl";
import { TImage } from "_common/types/baseTypes";
import { TGeoZoneInfosMdl } from "geoZones/_models/GeoZoneMdl";

export enum PROPERTY_SALES_TYPE {
    BROKER = "BROKER",
    SUBSCRIPTION = "SUBSCRIPTION",
    FREE = "FREE",
    PPS = "PPS",
}

export enum ORDER_STATUS {
    VALIDATED = "VALIDATED",
    NONE = "NONE",
    IN_PROGRESS = "IN_PROGRESS",
}

export enum PACKAGE_KEY {
    PREMIUM_PLAN = "premiumPlan",
    VEDETTE = "premiumVedette",
    SHOWCASE = "showcase",
    POPUP = "popup",
}

export enum PROPERTY_EVENT {
    PUBLISHED = "properties/published",
    PREMIUM_ALMOST_EXPIRED = "properties/premium/expired",
    PRINTED = "properties/printed",
    VISITED = "properties/visited",
    BUILDER_LINK = "properties/builderLink",
    BUILDER_PHONE = "properties/builderPhone",
    FAVORITED = "properties/favorited",
    PRICE_UPDATED = "properties/priceUpdated",
}

export enum PROPERTY_PURPOSE {
    BUY = "BUY",
    RENT = "RENT",
}

export enum PROPERTY_TYPE {
    condo = "condo",
    house = "house",
    multiplex = "multiplex",
    senior = "senior",
    eco = "eco",
    commercial = "commercial",
    land = "land",
}

export const PROPERTY_TYPE_BY_PURPOSE = {
    [PROPERTY_PURPOSE.BUY]: [
        PROPERTY_TYPE.condo,
        PROPERTY_TYPE.house,
        PROPERTY_TYPE.multiplex,
        PROPERTY_TYPE.land,
        PROPERTY_TYPE.commercial,
    ],
    [PROPERTY_PURPOSE.RENT]: [PROPERTY_TYPE.condo, PROPERTY_TYPE.house, PROPERTY_TYPE.senior, PROPERTY_TYPE.commercial],
};

export enum PROPERTY_SUBTYPE {
    fourSemiApartment = "fourSemiApartment",
    threeSemiApartment = "threeSemiApartment",
    rentStudio = "rentStudio",
    townHouse = "townHouse",
    singleFamily = "singleFamily",
    semiHouse = "semiHouse",
    duplex = "duplex",
    garage = "garage",
    triplex = "triplex",
    quadruplex = "quadruplex",
    quintuplex = "quintuplex",
}

export const PROPERTY_SUBTYPE_CONFIG: {
    [propertyType in PROPERTY_TYPE]: { [purpose in PROPERTY_PURPOSE]: PROPERTY_SUBTYPE[] };
} = {
    [PROPERTY_TYPE.condo]: {
        [PROPERTY_PURPOSE.BUY]: [],
        [PROPERTY_PURPOSE.RENT]: [
            PROPERTY_SUBTYPE.rentStudio,
            PROPERTY_SUBTYPE.fourSemiApartment,
            PROPERTY_SUBTYPE.threeSemiApartment,
        ],
    },
    [PROPERTY_TYPE.house]: {
        [PROPERTY_PURPOSE.BUY]: [PROPERTY_SUBTYPE.townHouse, PROPERTY_SUBTYPE.semiHouse],
        [PROPERTY_PURPOSE.RENT]: [],
    },
    [PROPERTY_TYPE.multiplex]: { [PROPERTY_PURPOSE.BUY]: [], [PROPERTY_PURPOSE.RENT]: [] },
    [PROPERTY_TYPE.senior]: { [PROPERTY_PURPOSE.BUY]: [], [PROPERTY_PURPOSE.RENT]: [] },
    [PROPERTY_TYPE.eco]: { [PROPERTY_PURPOSE.BUY]: [], [PROPERTY_PURPOSE.RENT]: [] },
    [PROPERTY_TYPE.commercial]: { [PROPERTY_PURPOSE.BUY]: [], [PROPERTY_PURPOSE.RENT]: [] },
    [PROPERTY_TYPE.land]: { [PROPERTY_PURPOSE.BUY]: [], [PROPERTY_PURPOSE.RENT]: [] },
};

export enum PROPERTY_STATUS {
    READY_TO_MOVE_IN = "READY_TO_MOVE_IN",
    UNDER_CONSTRUCTION = "UNDER_CONSTRUCTION",
    PROJECT = "PROJECT",
    SOON = "SOON",
}

export enum PREMIUM {
    NONE,
    SILVER,
    GOLD,
    PLATINE,
}

export enum PARKING_TYPE {
    OUTDOOR = "outdoor",
    INDOOR = "indoor",
    INDIVIDUAL = "individual",
}

export enum SEASONS {
    SPRING = "spring",
    SUMMER = "summer",
    AUTUMN = "autumn",
    WINTER = "winter",
}

export interface TPremiumPeriod {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
}

export interface TRangeNumber {
    min: number;
    max?: number;
}

export interface TPremium {
    premiumPlan: PREMIUM;
    premiumPlanPeriod?: TPremiumPeriod;
    premiumVedette: boolean;
    premiumVedettePeriod?: TPremiumPeriod;
    showcase: boolean;
    showcasePeriod?: TPremiumPeriod;
    popup: boolean;
}
export interface TInstalment {
    start: string;
    year: string;
    delivery: string;
    discount: string;
}

export interface TLocation {
    type: string | "Point";
    coordinates: [number, number] | number[];
}

export type TContact = {
    contactPhone: string;
    contactMail: string;
    propertyLink: string;
};

export interface TAddressMdl {
    street?: string;
    extra?: string;
    city: string;
    postalCode?: string;
    neighbourhood: string;
    country?: string;
    province: string;
    provinceLong?: string;
}

export interface TAmenity {
    [key: string]: string | number | boolean | undefined;
}

export interface TFeature {
    [key: string]: string | number | boolean | undefined;
}

export interface TPropertyLocalized {
    title: string;
    description: string;
    urlAlias: string;
    meta: TMeta;
    component: TRootComponent;
    propertyLink: string;
    label: {
        text: string;
    };
}

type TPropertyBaseMdl = {
    _id: string;
    published: boolean;
    salesType: PROPERTY_SALES_TYPE;
    purpose: PROPERTY_PURPOSE;
    type: PROPERTY_TYPE;
    subType?: PROPERTY_SUBTYPE;
    isApartment?: boolean;
    propertyBuilderName?: string;
    propertyBuilderWebsite?: string;

    price: TRangeNumber;
    taxIsIncluded: boolean;
    priceIsNotDisplayed: boolean;
    statusIsNotDisplayed: boolean;
    sold: boolean;
    soldDate: dayjs.Dayjs;
    status: PROPERTY_STATUS;

    label: {
        text: string;
        color: string;
        fontColor: string;
        override: boolean;
    };

    premium: TPremium;
    discount: boolean;
    address: TAddressMdl;
    location: TLocation;
    visit3D: string;
    isStreetViewDisabled: boolean;
    video: string;
    video2: string;

    amenities: TAmenity;
    features: TFeature;

    photos: TImage[];
    floorPlans: TImage[];
    showcaseImage: string;
    nbOfLevels: number;
    nbOfUnits: number;
    bedrooms: TRangeNumber;
    bathrooms: TRangeNumber;
    basementRooms: number;
    carParks: number;
    lockers: number;
    buildingHeight: number;
    squareSurface: TRangeNumber;

    instalments: TInstalment[];

    deliveryDates: dayjs.Dayjs[];

    position: number;
    waterFront: boolean;
    contactLabelPhone: string;
    contactPhone: string;
    contactMail: string;
    contactName: string;
    propertyLink: string;
    contacts: TContact[];
    planPoint: string;
    webhook: string;
    webhookTitle: string;
    isGroupPlanPoint: string;
    isAppointingFormDisabled: boolean;
    isContactFormDisabled: boolean;
    isLocalLogicDisabled: boolean;

    centrix?: {
        enable: boolean;
        list: number;
    };

    order?: {
        _id?: string;
        isCompleted: boolean;
    };
    orderStatus: ORDER_STATUS;
    htmlResponse?: {
        status: 404 | 302 | 301;
        url?: string;
    };

    source?: {
        platform: "duproprio" | "centris";
        id: string;
        url: string;
        enUrl?: string;
        developer: {
            name: string;
            url: string;
        };
    };
    createdAt: dayjs.Dayjs;
    updatedAt: dayjs.Dayjs;
};

export type TPropertyMdl = TPropertyBaseMdl & {
    localized: {
        fr: TPropertyLocalized;
        es: TPropertyLocalized;
        en: TPropertyLocalized;
        zh: TPropertyLocalized;
    };
    units?: TUnitMdl[];
    developer?: string;
    stats?: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
    };
};

export type TPropertyListingMdl = TPropertyBaseMdl & {
    localized: TPropertyLocalized;
    urlAlias: {
        [lang: string]: string;
    };
    developer?: TUserSummaryMdl;
    stats?: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
    };
    units: TUnitListingMdl[];
    geozones: (TGeoZoneInfosMdl | null)[];
    isPartial?: boolean;
    isPlacement?: boolean;
    placementId?: string;
    isFeatured?: boolean;
    isPremium?: boolean;
    isAutomatedAdding?: boolean;
    [key: string]: any;
};

export type TPropertyDashboardListingMdl = TPropertyListingMdl & {
    _localized: {
        fr: TPropertyLocalized;
        es: TPropertyLocalized;
        en: TPropertyLocalized;
        zh: TPropertyLocalized;
    };
    stats: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
        favorited: {
            count: number;
        };
    };
    features: TFeature;
};

export type TPropertyCardMdl = Pick<
    TPropertyListingMdl,
    | "_id"
    | "address"
    | "developer"
    | "sold"
    | "label"
    | "localized"
    | "photos"
    | "purpose"
    | "type"
    | "priceIsNotDisplayed"
    | "taxIsIncluded"
    | "price"
    | "bedrooms"
    | "bathrooms"
    | "squareSurface"
    | "isApartment"
    | "propertyBuilderName"
    | "location"
    | "placementId"
    | "isPremium"
    | "isFeatured"
>;
export function createDefaultProperty() {
    return {
        purpose: PROPERTY_PURPOSE.BUY,
        type: PROPERTY_TYPE.house,
        salesType: PROPERTY_SALES_TYPE.SUBSCRIPTION,
        localized: {
            fr: {
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
                propertyLink: "",
                label: {
                    text: "",
                },
            },
            en: {
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
                propertyLink: "",
                label: {
                    text: "",
                },
            },
            es: {
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
                propertyLink: "",
                label: {
                    text: "",
                },
            },
            zh: {
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
                propertyLink: "",
                label: {
                    text: "",
                },
            },
        },
        amenities: [],
        deliveryDates: [],
        developer: "",
        discount: false,
        features: [],
        floorPlans: [],
        instalments: [],
        location: {
            type: "Point",
            coordinates: [-73.5617, 45.5089],
        },
        photos: [],
        position: 0,
        premium: {
            premiumPlan: PREMIUM.NONE,
            premiumVedette: false,
        },
        nbOfLevels: 0,
        nbOfUnits: 0,
        price: { min: 0, max: 0 },
        taxIsIncluded: false,
        priceIsNotDisplayed: false,
        bedrooms: { min: 1, max: 1 },
        bathrooms: { min: 1, max: 1 },
        squareSurface: { min: 500, max: 2000 },
        published: false,
        sold: false,
        status: PROPERTY_STATUS.READY_TO_MOVE_IN,
        label: {
            text: undefined,
            color: "#ffffff",
            fontColor: "#1155ff",
            override: false,
        },
        units: [],
        visit3D: "",
        video: "",
        video2: "",
        contactPhone: "",
        contactMail: "",
        contactName: "",
        waterFront: false,
        address: {
            street: "",
            extra: "",
            city: "",
            postalCode: "",
            neighbourhood: "",
            country: "CA",
            province: "QC",
        },
        planPoint: "",
        webhook: "",
        webhookTitle: "",
        isGroupPlanPoint: false,
        isLocalLogicDisabled: false,
        contacts: [],
        stats: {
            printed: {
                count: 0,
                date: new Date(),
            },
            visited: {
                count: 0,
                date: new Date(),
            },
        },
        orderStatus: ORDER_STATUS.NONE,
    };
}

function _defaultLocalizedContent(title = "") {
    return {
        title: title,
        description: "",
        urlAlias: title,
        meta: {
            title: "",
            description: "",
        },
        component: { id: "div", props: { style: {}, children: [] } },
        propertyLink: "",
        label: {
            text: "",
        },
    };
}

export function createDefaultPropertyWithMandatoryTitle(title: string) {
    return {
        ...createDefaultProperty(),
        localized: {
            fr: _defaultLocalizedContent(title),
            en: _defaultLocalizedContent(title),
            es: _defaultLocalizedContent(title),
            zh: _defaultLocalizedContent(title),
        },
    };
}
