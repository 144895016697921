import React from "react";
import styles from "../_css/propertiesNoResults.module.css";
import stylesBtn from "./_css/propertiesNoResultsButton.module.css";
import { ButtonV2 } from "_common/ui/mui/buttons/ButtonV2";
import refresh from "../../assets/images/srps/refresh.svg";
import { useHistory } from "react-router-dom";
import { getUrlWithoutPropertyType } from "_common/_utils/filterUtils";
import { useTranslation } from "react-i18next";
import { PropertiesNoResultsExploreMoreButton } from "properties/listing/PropertiesNoResultsExploreMoreButton";
import { PropertiesNoResultsEditSearch } from "properties/listing/PropertiesNoResultsEditSearch";
import { panelStore } from "main/header/panel/_stores/panelStore";
import { MobileFilters } from "properties/searchBar/MobileFilters";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";

export function PropertiesNoResults() {
    const geoZone = geoZonesStore.geoZone;
    const hasGeoZoneAndLinkedGeoZoneRegion = geoZone && geoZone?._id && geoZone.linkedRegionId;
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div>
                <h2 className={styles.noResultLabel}>{t("propertiesPage.noResults.label")}</h2>
            </div>
            <div className={styles.btns}>
                <ButtonV2
                    onClick={() => {
                        listStore.removeFiltersExceptFollowingIds(["location", "purpose"]);
                        history.push(getUrlWithoutPropertyType(history.location.pathname));
                    }}
                    size={"medium"}
                    className={stylesBtn.btn}
                    variant={"contained"}
                    color={"primary"}
                >
                    <img src={refresh} className={"mr_5"} alt={"refresh icon"} />
                    <span className={styles.noResultResetText}>{t("propertiesPage.noResults.reset")}</span>
                </ButtonV2>

                <div className={"hiddenDesktop flex_center_center"}>{t("words.or").toUpperCase()}</div>

                {hasGeoZoneAndLinkedGeoZoneRegion ? (
                    <PropertiesNoResultsExploreMoreButton geoZoneId={geoZone!.linkedRegionId!} />
                ) : (
                    <div className={"hiddenDesktop"}>
                        <PropertiesNoResultsEditSearch
                            onClick={() => {
                                panelStore.open({
                                    full: true,
                                    title: t("words.filters"),
                                    children: <MobileFilters listStore={listStore} propertiesStore={propertiesStore} />,
                                    onClose: () => panelStore.close(),
                                    withSpinner: false,
                                    opened: true,
                                });
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
