import React from "react";
import Header from "main/header/Header";
import { PanelObserver } from "main/header/panel/PanelObserver";
import { NoSsr } from "@material-ui/core";
import { CompareOverlay } from "compare/CompareOverlay";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { userStore } from "users/_stores/userStore";
import { SignInModalObserver } from "users/auth/blocks/SignInModalObserver";
import { Footer } from "main/footer/Footer";
import { usePage } from "_common/_utils/hookUtils";
import { MenuProvider } from "main/header/_menu/MenuContext";
import { VirtualAssistantPopUp } from "virtualAssistant/pop-up/VirtualAssistantPopUp";

export const PAGE_REF: { current: HTMLDivElement | null } = { current: null };

export function PageRegular(props: any) {
    const {
        isVirtualAssistantPage,
        isRentOrBuyPage,
        isPropertyUnitPage,
        isSummaryOrderPageOrProjectPageForm,
    } = usePage();
    const footerIsHidden = isRentOrBuyPage || isSummaryOrderPageOrProjectPageForm;
    const doesRenderVirtualAssistantPopUp = !isPropertyUnitPage && !isVirtualAssistantPage;

    return (
        <MenuProvider>
            <div ref={(ref) => (PAGE_REF.current = ref)}>
                <NoSsr>
                    <PanelObserver />
                    <CompareOverlay type={MAIN_RESOURCE.PROPERTIES} />
                    <CompareOverlay type={MAIN_RESOURCE.UNITS} />
                    {!userStore.isLogged && <SignInModalObserver />}
                </NoSsr>
                <>
                    <Header />
                    <>{props.children}</>
                    {!footerIsHidden && <Footer />}
                </>
            </div>
            {doesRenderVirtualAssistantPopUp && <VirtualAssistantPopUp />}
        </MenuProvider>
    );
}
