import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { PropertiesStore } from "properties/_stores/propertiesStore";
import { TLang } from "_configs/sharedConfig";
import { capitalize } from "_common/_utils/alphaNumUtils";

export function usePropertiesPageMeta(propertiesStore: PropertiesStore) {
    const { t, i18n } = useTranslation();
    const {
        region,
        cityForMeta,
        isGeoZonePage,
        isExistingPropertyType,
        garageParams,
        propertySubTypeLocalized,
        propertySubTypeKey,
        propertyTypeLocalized,
        propertyTypeKey,
    } = useSearchPageParams();
    let isCustomSeoText;

    const propertyTypeOrSubTypeKey = propertySubTypeKey ?? propertyTypeKey;
    const propertyTypeOrSubTypeLocalized = propertySubTypeLocalized ?? propertyTypeLocalized;
    let metaTitle =
        propertiesStore.purpose === PROPERTY_PURPOSE.BUY
            ? t("propertiesPage.meta.buyGenericMetaTitle")
            : t("propertiesPage.meta.rentGenericMetaTitle");
    let metaDescription =
        propertiesStore.purpose === PROPERTY_PURPOSE.BUY
            ? t("propertiesPage.meta.buyGenericMetaDescription")
            : t("propertiesPage.meta.buyGenericMetaDescription");
    let title =
        propertiesStore.purpose === PROPERTY_PURPOSE.BUY
            ? t("propertiesPage.meta.buyH1Title")
            : t("propertiesPage.meta.rentH1Title");
    let seoText =
        propertiesStore.purpose === PROPERTY_PURPOSE.BUY
            ? t("propertiesPage.meta.buyGenericText")
            : t("propertiesPage.meta.rentGenericText");
    if (propertyTypeOrSubTypeKey && isExistingPropertyType) {
        metaTitle = t(`propertiesPage.meta.${propertiesStore.purpose}.title.` + propertyTypeOrSubTypeKey);
        metaDescription = t(`propertiesPage.meta.${propertiesStore.purpose}.description.` + propertyTypeOrSubTypeKey);
        title = t(`propertiesPage.meta.${propertiesStore.purpose}.h1.` + propertyTypeOrSubTypeKey);
        seoText = t(`propertiesPage.meta.${propertiesStore.purpose}.seo.` + propertyTypeOrSubTypeKey);
    }

    if (garageParams) {
        metaTitle = t(`propertiesPage.meta.${propertiesStore.purpose}.title.garage`, {
            propertyType: capitalize(propertyTypeOrSubTypeLocalized),
        });
        metaDescription = t(`propertiesPage.meta.${propertiesStore.purpose}.description.garage`, {
            propertyType: capitalize(propertyTypeOrSubTypeLocalized),
        });
        title = t(`propertiesPage.meta.${propertiesStore.purpose}.h1.garage`, {
            propertyType: capitalize(propertyTypeOrSubTypeLocalized),
        });

        const seoKey = `propertiesPage.meta.${propertiesStore.purpose}.description.variants.garage.${geoZonesStore.geoZone?.variant}`;
        seoText = i18n.exists(seoKey) ? t(seoKey) : "";
    }

    if (isGeoZonePage) {
        const geoZoneName = propertiesStore.addressParams?.address
            ? propertiesStore.addressParams?.address.split(",")[0]
            : propertiesStore.addressParams?.neighbourhood
            ? propertiesStore.addressParams.neighbourhood
            : propertiesStore.addressParams?.city &&
              propertiesStore.addressParams.city.toLowerCase() === cityForMeta?.toLowerCase()
            ? propertiesStore.addressParams.city
            : propertiesStore.addressParams.region &&
              propertiesStore.addressParams.region.toLowerCase() === cityForMeta?.toLowerCase()
            ? propertiesStore.addressParams.region
            : cityForMeta;

        metaTitle = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.title.generic`, {
            geoZoneName: geoZoneName,
        });
        metaDescription = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.description.generic`, {
            geoZoneName: geoZoneName,
        });
        title = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.generic`, {
            geoZoneName: geoZoneName,
        });
        seoText = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.seo.generic`, {
            geoZoneName: geoZoneName,
        });
        if (propertyTypeOrSubTypeKey && isExistingPropertyType) {
            if (garageParams) {
                metaTitle = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.title.garage`, {
                    geoZoneName: geoZoneName,
                    propertyType: capitalize(propertyTypeOrSubTypeLocalized),
                });
                metaDescription = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.description.garage`, {
                    geoZoneName: geoZoneName,
                    propertyType: capitalize(propertyTypeOrSubTypeLocalized),
                });
                title = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.garage`, {
                    geoZoneName: geoZoneName,
                    propertyType: capitalize(propertyTypeOrSubTypeLocalized),
                });
                const seoKey = `propertiesPage.meta.geoZone.${propertiesStore.purpose}.description.variants.garage.${geoZonesStore.geoZone?.variant}`;
                seoText = i18n.exists(seoKey, {
                    geoZoneName: geoZoneName,
                })
                    ? t(seoKey, {
                          geoZoneName: geoZoneName,
                      })
                    : "";
            } else {
                metaTitle = t(
                    `propertiesPage.meta.geoZone.${propertiesStore.purpose}.title.` + propertyTypeOrSubTypeKey,
                    {
                        geoZoneName: geoZoneName,
                    },
                );
                metaDescription = t(
                    `propertiesPage.meta.geoZone.${propertiesStore.purpose}.description.` + propertyTypeOrSubTypeKey,
                    {
                        geoZoneName: geoZoneName,
                    },
                );
                title = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + propertyTypeOrSubTypeKey, {
                    geoZoneName: geoZoneName,
                });
                const seoKey = `propertiesPage.meta.geoZone.${propertiesStore.purpose}.description.variants.${propertyTypeOrSubTypeKey}.${geoZonesStore.geoZone?.variant}`;
                seoText = i18n.exists(seoKey, {
                    geoZoneName: geoZoneName,
                })
                    ? t(seoKey, {
                          geoZoneName: geoZoneName,
                      })
                    : "";
            }
        }
    }

    // CUSTOM META DEFINED IN GEOZONE
    if (geoZonesStore.geoZone && propertiesStore.purpose && !garageParams && !propertySubTypeKey) {
        if (propertyTypeKey && isExistingPropertyType) {
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                    propertyTypeOrSubTypeKey as PROPERTY_TYPE
                ]?.title
            ) {
                title =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                        propertyTypeOrSubTypeKey as PROPERTY_TYPE
                    ]?.title;
            }
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                    propertyTypeOrSubTypeKey as PROPERTY_TYPE
                ]?.meta?.title
            ) {
                metaTitle =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                        propertyTypeOrSubTypeKey as PROPERTY_TYPE
                    ]?.meta?.title ?? "";
            }
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                    propertyTypeOrSubTypeKey as PROPERTY_TYPE
                ]?.text
            ) {
                isCustomSeoText = true;
                seoText =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                        propertyTypeOrSubTypeKey as PROPERTY_TYPE
                    ]?.text;
            }
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                    propertyTypeOrSubTypeKey as PROPERTY_TYPE
                ]?.meta?.description
            ) {
                metaDescription =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                        propertyTypeOrSubTypeKey as PROPERTY_TYPE
                    ]?.meta?.description ?? "";
            }
        } else {
            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.title) {
                title = geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.title;
            }

            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.text) {
                isCustomSeoText = true;
                seoText = geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.text;
            }

            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.meta?.title) {
                metaTitle =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.meta
                        ?.title ?? "";
            }

            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.meta
                    ?.description
            ) {
                metaDescription =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.meta
                        ?.description ?? "";
            }
        }
    } else if (region && cityForMeta && !garageParams && !propertySubTypeKey) {
        title = t("propertiesPage.meta.title", { region, city: cityForMeta });
    }
    return { title, metaTitle, seoText, metaDescription, isCustomSeoText, propertyType: propertyTypeOrSubTypeKey };
}
