import React from "react";
import { Link } from "react-router-dom";
import { refreshPage } from "_common/_utils/pageUtils";
import styles from "properties/_css/propertiesPopularSearche.module.css";
import { Tag } from "properties/PropertiesPopularSearch";

type Props = {
    tags: Tag[];
};

function PopularTag({ tag }: { tag: Tag }) {
    return (
        <Link to={tag.url} onClick={() => refreshPage(tag.url)} key={tag.url} className={styles.propertySubTypeItem}>
            {tag.label}
        </Link>
    );
}

export function PropertiesPopularTags({ tags }: Props) {
    return (
        <>
            {tags.map((tag) => {
                return <PopularTag key={tag.url} tag={tag} />;
            })}
        </>
    );
}
