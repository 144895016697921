import React, { useState } from "react";
import styles from "../_css/propertyOrUnitCta.module.css";
import clsx from "clsx";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FormProvider, useForm } from "react-hook-form";
import { PropertyOrUnitContactFormBtn } from "properties/propertyPage/contact-form/property-or-unit-contact-form-btn";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { PROPERTY_EVENT, PROPERTY_SALES_TYPE } from "properties/_models/PropertyMdl";
import { useUnitStore } from "units/UnitContext";
import { UNIT_EVENT } from "units/_models/UnitMdl";
import { CheckboxAgree } from "properties/propertyPage/_components/CheckboxAgree";
import { observer } from "mobx-react";
import { RecaptchaDisclaimer } from "components/misc/contactUs/recaptchaDisclaimer";
import { PropertyOrUnitCtaFromExternalSource } from "properties/propertyPage/PropertyOrUnitCtaFromExternalSource";
import { CallCtaIcon } from "properties/propertyPage/_components/CallCtaIcon";
import { SIGN_UP_FROM, SIGN_UP_TYPE } from "users/_models/UserMdl";
import { Broker } from "properties/propertyPage/contact-form/broker";
import { SubscriptionOrPps } from "properties/propertyPage/contact-form/subscriptionOrPps";

export type TAskForVisitOrInfoForm = {
    name: string;
    phone: string;
    email: string;
    date: string;
    message?: string;
    propertyId?: string;
    unitId?: string;
    lang?: string;
    notification?: boolean;
    signUpType?: SIGN_UP_TYPE;
    signUpFrom?: SIGN_UP_FROM;
};

const PropertyOrUnitContactForm = observer(() => {
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const [phoneIsVisible, setPhoneIsVisible] = useState(false);
    const [hasAgreedNotification, setHasAgreedNotification] = useState(false);
    const { t } = useTranslation();

    if (
        propertyStore.property.source?.platform === "centris" ||
        propertyStore.property.source?.platform === "duproprio"
    ) {
        return <PropertyOrUnitCtaFromExternalSource link={propertyStore.property.localized.propertyLink} />;
    }

    const form = useForm<TAskForVisitOrInfoForm>({
        mode: "onBlur",
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            message: t("propertyPage.contact-form.message") + propertyStore.property.localized.title + ".",
        },
    });

    const hiddenPhone = propertyStore.property?.contactPhone.toString().slice(0, -4) + "XXXX";
    const appointingFormIsDisabled =
        propertyStore.property.developer?.isAppointingFormDisabled || propertyStore.property.isAppointingFormDisabled;
    if (appointingFormIsDisabled && propertyStore.property.isContactFormDisabled) return null;

    const hasPhoneAndIsNotBrokerSalesTypeProperty =
        propertyStore.property.contactPhone && propertyStore.property.salesType !== PROPERTY_SALES_TYPE.BROKER;

    return (
        <FormProvider {...form}>
            <div className={styles.container}>
                {propertyStore.property.salesType === PROPERTY_SALES_TYPE.BROKER ? <Broker /> : <SubscriptionOrPps />}
                <div className={"mt_25"}>
                    <CheckboxAgree checked={hasAgreedNotification} onChange={setHasAgreedNotification} />
                </div>
                <div className={"mt_25"}>
                    <PropertyOrUnitContactFormBtn hasAgreedNotification={hasAgreedNotification} />
                </div>
                <div className="mt_25">
                    <RecaptchaDisclaimer />
                </div>
            </div>
            {hasPhoneAndIsNotBrokerSalesTypeProperty && (
                <div className={styles.phoneContainer}>
                    <div className={styles.orContainer}>
                        <div className={styles.or}>{t("words.OR")}</div>
                    </div>
                    <div className={clsx("flex_row_center mb_5 justifyContent_center")}>
                        <a
                            href={`tel:${propertyStore.property.contactPhone}`}
                            className={clsx("flex_row_center", styles.text, styles.phoneTextContainer)}
                            onClick={() => {
                                if (phoneIsVisible) return;
                                setPhoneIsVisible(!phoneIsVisible);

                                Object.keys(unitStore).length > 0
                                    ? analyticsUtils.tracksEventOfItem(UNIT_EVENT.BUILDER_PHONE, unitStore.unit._id)
                                    : analyticsUtils.tracksEventOfItem(
                                          PROPERTY_EVENT.BUILDER_PHONE,
                                          propertyStore.property._id,
                                      );
                            }}
                        >
                            <CallCtaIcon />
                            <div className={"flex_row_center mt_3"}>
                                <div className={"text_bold ml_5"}>{t("words.call")}</div>
                                <div className={clsx("ml_5")}>
                                    {!phoneIsVisible ? hiddenPhone : propertyStore.property.contactPhone}
                                </div>
                            </div>
                        </a>
                    </div>
                    {phoneIsVisible && (
                        <div className={styles.mentionContainer}>
                            <div className={styles.mentionText}>{t("propertyPage.contact-form.contactMention")}</div>
                        </div>
                    )}
                </div>
            )}
        </FormProvider>
    );
});

export default PropertyOrUnitContactForm;
