import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { refreshPage } from "_common/_utils/pageUtils";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import styles from "./_css/advancedResearches.module.css";
import clsx from "clsx";
import { Collapsible } from "_common/ui/components/Collapsible";
import { useWindowSize } from "_common/_utils/hookUtils";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { TLang } from "_configs/sharedConfig";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { GEO_ZONE_TYPE } from "geoZones/_models/GeoZoneMdl";

type Props = {
    links: {
        to: string;
        label: string;
    }[];
};

function _AdvancedResearches({ links }: Props) {
    return (
        <Grid container spacing={1}>
            {links.map((link, i) => (
                <Grid key={i} item sm={12} md={6} lg={4} xl={3} className={styles.containerLink}>
                    <ExternalOrNavLink isBuyingOrIsRentingUrl={true} url={link.to} onClick={() => refreshPage(link.to)}>
                        <span className={clsx("fontWeight_normal", styles.link)}>{link.label}</span>
                    </ExternalOrNavLink>
                </Grid>
            ))}
        </Grid>
    );
}
export function AdvancedResearches({ links }: Props) {
    const { t, i18n } = useTranslation();
    const { width } = useWindowSize();
    const propertiesStore = usePropertiesStore();

    if (!geoZonesStore.geoZone) return null;

    if (links.length === 0) return null;

    const displayCollapse =
        width > 1750
            ? links.length > 20
            : width > 1280
            ? links.length > 15
            : width > 1000
            ? links.length > 10
            : links.length > 5;

    const geoZoneName =
        geoZonesStore.geoZone.type === GEO_ZONE_TYPE.NEIGHBORHOOD
            ? geoZonesStore.geoZone.address.neighbourhood
            : geoZonesStore.zoneName;
    const advancedTitle =
        geoZonesStore.geoZone.localized?.[i18n.language as TLang]?.[propertiesStore.purpose as PROPERTY_PURPOSE]?.srps
            ?.advanced;

    return (
        <div className={styles.container}>
            <h2 className={clsx(styles.title)}>{advancedTitle || t("advancedResearches.title", { geoZoneName })}</h2>
            <div className={styles.containerAdvancedResearches}>
                {displayCollapse ? (
                    <Collapsible moreText={t("advancedResearches.seeMore")} heightOfCollapsable={150}>
                        <_AdvancedResearches links={links} />
                    </Collapsible>
                ) : (
                    <_AdvancedResearches links={links} />
                )}
            </div>
        </div>
    );
}
