import React, { useState } from "react";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { ListStore } from "_common/list/ListStore";
import { useTranslation } from "react-i18next";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import styles from "../_css/propertiesSort.module.css";
import settings from "../../assets/images/settings.svg";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export function PropertiesSort(props: Props) {
    const [keySort, setKeySort] = useState({ key: "position", sort: -1 });
    const { t } = useTranslation();

    function sort(key?: string, sort?: number) {
        const _keySort = { key: key ?? keySort.key, sort: sort ?? keySort.sort };
        setKeySort(_keySort);
        props.listStore.setSort({ [_keySort.key]: _keySort.sort });
    }

    if (props.listStore.count === 0) return null;

    return (
        <div className="flex flex_row_center">
            <img className={styles.img} width={16} src={settings} alt={"settings"} />
            <div className={styles.sortBy}>{t("propertiesPage.sortBy")}</div>
            <UiSelect className={styles.btnSort} defaultValue={keySort.key} title={"Sort"}>
                <MenuItem onClick={() => sort(undefined)} value={"position"}>
                    {t("propertiesPage.sort.default")}
                </MenuItem>
                <MenuItem onClick={() => sort("createdAt", -1)} value={"deliveryDates.0"}>
                    {t("propertiesPage.sort.createdAt")}
                </MenuItem>
                <MenuItem onClick={() => sort("deliveryDates.0", -1)} value={"date"}>
                    {t("propertiesPage.sort.date")}
                </MenuItem>
                <MenuItem onClick={() => sort("price.min", -1)} value={"priceAsc"}>
                    {t("propertiesPage.sort.priceAsc")}
                </MenuItem>
                <MenuItem onClick={() => sort("price.min", 1)} value={"priceDesc"}>
                    {t("propertiesPage.sort.priceDesc")}
                </MenuItem>
            </UiSelect>
        </div>
    );
}
