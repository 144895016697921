import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import {
    PLACEMENT_PROPERTY_TYPE,
    PLACEMENT_TYPE,
    TPlacementListingLocalizedMdl,
    TPlacementMdl,
} from "placements/_models/PlacementsMdl";
import { action, observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import i18next from "i18next";

class PlacementsStore extends BaseResourceStore<TPlacementMdl> {
    @observable placementsSrps: TPlacementListingLocalizedMdl[] = [];
    @observable placementsSrpsState = new LoadingStateMdl<TPlacementListingLocalizedMdl[]>();

    @observable placementMedia: TPlacementListingLocalizedMdl | undefined;
    @observable placementsMediaState = new LoadingStateMdl<TPlacementListingLocalizedMdl[]>();

    @observable placementSlider: TPlacementListingLocalizedMdl | undefined;
    @observable placementSliderState = new LoadingStateMdl<TPlacementListingLocalizedMdl>();

    constructor() {
        super("placements");
        this.onInit();
    }

    @action fetchSliderProperty(propertyId: string) {
        if (!this.placementSliderState.isLoading) {
            this.placementSliderState.startLoading();
            this.placementSlider = undefined;
            const promise = fetchUtils.get<TPlacementListingLocalizedMdl[]>(
                `${this.apiPath}/${PLACEMENT_TYPE.SLIDER}/${propertyId}?lang=${i18next.language}`,
            );
            promise.then(
                ({ data }) => {
                    this.placementSliderState.setSuccess(data[0]);
                    this.placementSlider = data[0];
                },
                (error) => {
                    this.placementSliderState.setError(error);
                },
            );
        }
    }

    @action fetchMediaProperty(propertyId: string) {
        if (!this.placementsMediaState.isLoading) {
            this.placementsMediaState.startLoading();
            this.placementMedia = undefined;
            const promise = fetchUtils.get<TPlacementListingLocalizedMdl[]>(
                `${this.apiPath}/${PLACEMENT_TYPE.MEDIA}/${propertyId}?lang=${i18next.language}`,
            );
            promise.then(
                action(({ data }) => {
                    this.placementsMediaState.setSuccess(data);
                    this.placementMedia = data[0];
                }),
                (error) => {
                    this.placementsMediaState.setError(error);
                },
            );
        }
    }

    @action fetchSrpsPlacements(placementType: PLACEMENT_PROPERTY_TYPE, geoZoneId?: string) {
        if (!this.placementsSrpsState.isLoading) {
            this.placementsSrpsState.startLoading();
            this.placementsSrps = [];
            const promise = fetchUtils.get<TPlacementListingLocalizedMdl[]>(
                `${this.apiPath}/srps/${placementType}${geoZoneId ? `/${geoZoneId}` : ""}?lang=${i18next.language}`,
            );
            promise.then(
                ({ data }) => {
                    this.placementsSrps = data;
                    this.placementsSrpsState.setSuccess(data);
                },
                (error) => {
                    this.placementsSrpsState.setError(error);
                },
            );
        }
    }
}

const placementsStore = new PlacementsStore();
export { placementsStore };
