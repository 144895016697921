import React from "react";
import styles from "./_css/propertiesPopularSearche.module.css";
import {
    PROPERTY_PURPOSE,
    PROPERTY_SUBTYPE,
    PROPERTY_SUBTYPE_CONFIG,
    PROPERTY_TYPE,
} from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { PropertiesPopularTags } from "properties/PropertiesPopularTags";
import { PROPERTY_OPTION } from "_configs/propertyConfig";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { getSearchResultPageUrlFromGeoZonePurposeAndType } from "_common/_utils/geoZoneUtils";
import { observer } from "mobx-react";

export type Tag = {
    url: string;
    label: string;
};

export const PropertiesPopularSearch = observer(() => {
    const { t } = useTranslation();
    const { geoZone } = geoZonesStore;
    const { purpose } = usePropertiesStore();
    const { propertySubTypeKey, propertyTypeKey } = useSearchPageParams();

    if (!purpose || !propertyTypeKey) return null;

    const propertySubTypeFiltered =
        propertyTypeKey && !propertySubTypeKey ? PROPERTY_SUBTYPE_CONFIG[propertyTypeKey][purpose] : [];

    const tags: Tag[] = [];

    propertySubTypeFiltered.forEach((propertySubType: PROPERTY_SUBTYPE) => {
        if (propertySubTypeKey) return null;

        tags.push({
            url: getSearchResultPageUrlFromGeoZonePurposeAndType(
                geoZone,
                purpose,
                t(`property.typesUrl.${propertyTypeKey}`),
                undefined,
                t(`property.subTypesUrl.${propertySubType}`),
            ),
            label: t(`property.subTypes.${propertySubType}`),
        });
    });

    const garageTag: Tag = {
        url: getSearchResultPageUrlFromGeoZonePurposeAndType(
            geoZone,
            purpose,
            t(`property.typesUrl.${propertyTypeKey}`),
            undefined,
            t(`property.featuresUrls.${PROPERTY_OPTION.indoorParking}`),
        ),
        label: t(`property.features.${PROPERTY_OPTION.indoorParking}`),
    };

    if (propertyTypeKey === PROPERTY_TYPE.condo) tags.push(garageTag);
    if (propertyTypeKey === PROPERTY_TYPE.house && purpose === PROPERTY_PURPOSE.BUY) {
        tags.push(garageTag);
    }
    if (!tags.length) return null;
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{t("propertiesPage.popularSearch.title")}</h2>
            <div className={styles.propertySubTypeList}>
                <PropertiesPopularTags tags={tags} />
            </div>
        </div>
    );
});
