import React, { lazy, useEffect, useRef } from "react";
import clsx from "clsx";
import styles from "../_css/propertiesView.module.css";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PropertiesViewList } from "properties/listing/PropertiesViewList";
import { PropertyResetButton } from "properties/propertyResetButton";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import Suspenser from "_common/loaders/Suspenser";
import { PropertiesSort } from "properties/listing/PropertiesSort";
import { MAP_OR_LIST_SELECTED, usePropertiesPageContext } from "properties/PropertiesPageContext";
import { PropertiesViewButtonsMobile } from "properties/listing/PropertiesViewButtonsMobile";
import { PropertiesViewHeader } from "properties/listing/PropertiesViewHeader";
import { PropertiesCount } from "properties/listing/PropertiesCount";
import { scrollTop } from "_common/_utils/ScrollTop";

export const MAP_REF: { current: unknown | null } = { current: null };

const LazyMapRender = lazy(() => import("maps/MapRender"));

export const PropertiesView = () => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);

    const resultsContainer = useRef<HTMLDivElement>(null);
    const { mapOrListSelected } = usePropertiesPageContext();

    useEffect(() => {
        setTimeout(() => {
            if (resultsContainer && resultsContainer?.current?.scrollTop) {
                scrollTop(resultsContainer, resultsContainer.current.scrollTop);
            }
        }, 250);
    }, [listStore.selectedPage, mapOrListSelected]);

    const displayCountIfResultsOrNoGeozone = (!!listStore.count && listStore.count > 0) || !geoZonesStore.geoZone;

    return (
        <>
            <div
                className={clsx("flex_row flex-1 position_relative", styles.container)}
                onClick={() => {
                    geoZonesStore.open(false);
                }}
                onMouseLeave={() => propertiesStore.setMapPropertiesSelected(undefined)}
            >
                <div
                    ref={resultsContainer}
                    className={clsx("flex_column", styles.results, {
                        [styles.listOnly]: mapOrListSelected === MAP_OR_LIST_SELECTED.LIST,
                        ["w-half"]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                        [styles.resultWithMap]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                    })}
                >
                    {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP && (
                        <div className={"ph_20"}>
                            <PropertiesViewHeader />
                        </div>
                    )}
                    <div className={clsx(styles.contentResult, "ph_20 flex_row justifyContent_spaceBetween mb_10")}>
                        <PropertiesSort listStore={listStore} />
                        {displayCountIfResultsOrNoGeozone && <PropertiesCount />}
                    </div>
                    <PropertiesViewList />
                </div>
                {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP && (
                    <div className={"flex_row flex-1 position_relative"}>
                        <Suspenser noSpinner noText>
                            <LazyMapRender />
                        </Suspenser>
                        {geoZonesStore.geoZone && (
                            <div className="position_absolute" style={{ bottom: 0, left: 0, margin: 10 }}>
                                <PropertyResetButton />
                            </div>
                        )}
                    </div>
                )}
                <PropertiesViewButtonsMobile />
            </div>
        </>
    );
};
