import React from "react";
import { GridPropertyCard } from "properties/GridPropertyCard";
import { PropertyCard } from "properties/PropertyCard";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { observer } from "mobx-react";
import { PropertiesNoResults } from "properties/listing/PropertiesNoResults";
import { PlacementSrps } from "placements/PlacementSrps";

export const PropertiesViewListWithPlacements = observer(() => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);

    const properties = listStore.paginatedItems;
    const hasNoResults = !listStore?.count || listStore.count === 0;
    if (hasNoResults) return <PropertiesNoResults />;

    return (
        <>
            {properties.map((property, idx) => {
                if (!property) return null;
                return (
                    <React.Fragment key={property._id + idx}>
                        <GridPropertyCard>
                            <PropertyCard
                                property={property}
                                onMouseOver={() => {
                                    propertiesStore.setMapPropertiesSelected(property);
                                }}
                                onMouseLeave={() => {
                                    propertiesStore.setMapPropertiesSelected.cancel();
                                }}
                            />
                        </GridPropertyCard>
                        <PlacementSrps listStore={listStore} srpIndex={idx} />
                    </React.Fragment>
                );
            })}
        </>
    );
});
