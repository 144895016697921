import React from "react";
import { JsonLdBreadCrumb } from "breadcrumb/JsonLdBreadCrumb";
import { Breadcrumb, TBreadcrumbItem } from "breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { IconComponent } from "components/base/icon/IconComponent";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { observer } from "mobx-react";

export const PropertiesBreadcrumbContent = observer(() => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const { geoZone } = geoZonesStore;

    const {
        isCityPage: isAtLeastCityOrRegionPage,
        isNeighborhoodPage: isAtLeastNeighborhoodPage,
        propertyTypeKey,
    } = useSearchPageParams();

    const promise = new Promise((resolve) => {
        resolve(true);
    });
    const { loading } = useLoadingFromPromise(promise);

    if (!loading || !geoZone || geoZone.type === "PROVINCE") return null;

    const geoZoneName = "";



    const items: TBreadcrumbItem[] = [
        {
            itemLabel:
                propertiesStore.purpose === PROPERTY_PURPOSE.BUY
                    ? t("propertiesPage.meta.buyGenericMetaTitle")
                    : t("propertiesPage.meta.rentGenericMetaTitle"),
            pageTitle:
                propertiesStore.purpose === PROPERTY_PURPOSE.BUY
                    ? t("propertiesPage.meta.buyGenericMetaTitle")
                    : t("propertiesPage.meta.rentGenericMetaTitle"),
            icon: <IconComponent icon="icon-home" />,
            url: URLS.buy(),
            forceReload: true,
        },
    ];

    let currentPageTitle: string = t(
        `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyTypeKey ?? "generic"),
        {
            geoZoneName,
        },
    );

    if (isAtLeastCityOrRegionPage) {
        if (geoZone.isRegion) {
            currentPageTitle = t(
                `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyTypeKey ?? "generic"),
                {
                    geoZoneName: geoZone?.address?.city,
                },
            );
        } else {
            const region = geoZonesStore.parents?.[geoZone._id]?.find((gz) => gz.isRegion);
            if (region) {
                items.push({
                    itemLabel: region.name.split(",")[0],
                    url: URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                        undefined,
                        reformatStringForUrls(geoZone.address.province),
                        reformatStringForUrls(region.address.city),
                    ),
                    forceReload: true,
                    pageTitle: t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.generic`, {
                        geoZoneName: geoZone?.address?.city,
                    }),
                });
            }

            currentPageTitle = t(
                `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyTypeKey ?? "generic"),
                {
                    geoZoneName: geoZone?.address?.city,
                },
            );
        }
    }

    if (isAtLeastNeighborhoodPage) {
        const city = geoZonesStore.parents?.[geoZone._id]?.find((gz) => !gz.isRegion);
        if (city) {
            items.push({
                itemLabel: city.name.split(",")[0],
                url: URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                    undefined,
                    reformatStringForUrls(geoZone.address.province),
                    reformatStringForUrls(city.address.city),
                ),
                forceReload: true,
                pageTitle: t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.generic`, {
                    geoZoneName: city?.address?.city,
                }),
            });
        }
        currentPageTitle = t(
            `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyTypeKey ?? "generic"),
            {
                geoZoneName: geoZone?.address?.neighbourhood,
            },
        );
    }

    if (propertyTypeKey) {
        items.push({
            itemLabel: isAtLeastNeighborhoodPage
                ? geoZone?.address?.neighbourhood
                : isAtLeastCityOrRegionPage
                ? geoZone?.address?.city
                : geoZone?.address?.provinceLong,
            url: URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(
                    !isAtLeastNeighborhoodPage && !isAtLeastCityOrRegionPage
                        ? geoZone.address.provinceLong
                        : geoZone.address.province,
                ),
                isAtLeastCityOrRegionPage ? reformatStringForUrls(geoZone.address.city) : undefined,
                isAtLeastNeighborhoodPage ? reformatStringForUrls(geoZone.address.neighbourhood) : undefined,
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.generic`, {
                geoZoneName: isAtLeastNeighborhoodPage
                    ? geoZone?.address?.neighbourhood
                    : isAtLeastCityOrRegionPage
                    ? geoZone?.address?.city
                    : geoZone?.address?.provinceLong,
            }),
        });
    }

    return (
        <>
            <JsonLdBreadCrumb
                items={items}
                currentItem={{
                    itemLabel: currentPageTitle,
                }}
            />
            <Breadcrumb noHome currentItem={{ itemLabel: currentPageTitle }} items={items} />
        </>
    );
});
